import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import LoadingDots from "../../components/LoadingDots";
import NotificationBanner from "../../components/NotificationBanner";
import { scrollToTop } from "../../helpers/scrollHelper";

const PublishMasterTraits = () => {
  const [stgMasterTraits, setStgMasterTraits] = useState([]);
  const [prodMasterTraits, setProdMasterTraits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [publishing, setPublishing] = useState({ active: false, index: null });
  const [flashMessage, setFlashMessage] = useState({
    duration: "0",
    message: "",
    status: "success",
  });

  const stgEndpoint =
    process.env.REACT_APP_FEATURE_FLAG === "production"
      ? "https://staging.dna.itovi.com/api"
      : process.env.REACT_APP_DNA_API;
  const prodEndpoint = process.env.REACT_APP_DNA_API;

  const getStgMasterTraits = useCallback(async () => {
    const result = await axios
        .get(stgEndpoint + "/v2/mastertraits")
        .then(async (response) => {
            if (Array.isArray(response.data)) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch((error) => {
            return [];
        });

    return result;
  }, [stgEndpoint]);

  const getProdMasterTraits = useCallback(async () => {
    const result = await axios
        .get(prodEndpoint + "/v2/mastertraits")
        .then((response) => {
            if (Array.isArray(response.data)) {
                return response.data;
            } else {
                return [];
            }
        })
        .catch((error) => {
            return [];
        });

    return result;
  }, [prodEndpoint]);

  const setMasterTraits = useCallback(async () => {
    const stgTraits = await getStgMasterTraits();
    const prodTraits = await getProdMasterTraits();

    if (stgTraits.length && prodTraits.length) {
      return {
        stg: stgTraits,
        prod: prodTraits,
      };
    } else {
      setFlashMessage({
        duration: "none",
        message: "Production connection error!",
        status: "danger",
      });
      return null;
    }
  }, [getProdMasterTraits, getStgMasterTraits]);

  useEffect(() => {
    let isSubscribed = true;

    setMasterTraits().then((response) => {
      if (isSubscribed && response) {
        setStgMasterTraits(response.stg);
        setProdMasterTraits(response.prod);
      }

      if (isSubscribed) {
        setLoading(false);
      }
    });

    return () => (isSubscribed = false);
  }, [setMasterTraits]);

  const publish = (masterTrait, index) => {
    setPublishing({ active: true, index: index });
    delete masterTrait._id;
    axios
        .post(process.env.REACT_APP_DNA_API + "/v1/mastertraits/update", masterTrait, {
            headers: {
                authorization: "Token " + process.env.REACT_APP_DNA_API_KEY
            }
        })
        .then(async (response) => {
            if (response.data.type === "ok" && response.data.result) {
                const result = await setMasterTraits();
                if (result) {
                    setStgMasterTraits(result.stg);
                    setProdMasterTraits(result.prod);
                }
            }
            setPublishing({ active: false, index: null });
        })
        .catch((error) => {
            setPublishing({ active: false, index: null });
            scrollToTop();
            setFlashMessage({
                duration: "15000",
                message: "The master trait was not published successfully!",
                status: "danger"
            });
        });
  };

  return (
    <div className="publish-master-traits sm-container">
      {loading ? (
        <LoadingDots />
      ) : (
        <div className="traits-publish-list">
          <NotificationBanner
            status={flashMessage.status}
            duration={flashMessage.duration}
            message={flashMessage.message}
            setFlashMessage={setFlashMessage}
          />
          <div className="page-title">Publish Master Traits</div>
          {stgMasterTraits.length && prodMasterTraits.length
            ? stgMasterTraits
                .sort((a, b) => a.traitName.localeCompare(b.traitName))
                .map((val, index) => {
                  const prodTrait = prodMasterTraits.filter(
                    (obj) => obj.traitId === val.traitId
                  )[0];
                  return (
                    <div className="trait" key={index}>
                      <div className="trait-image">
                        {val.imageUrl || val.imageUrl !== "" ? (
                          <img
                            src={val.imageUrl}
                            alt="trait"
                            onError={(e) => {
                              e.target.style.display = "none";
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="trait-name">{val.traitName}</div>
                      <div className="trait-last-updated">
                        <div>Last Update</div>
                        {val.updated_at ? val.updated_at : "Unknown"}
                      </div>
                      <div className="trait-status">
                        {prodTrait &&
                        val.updated_at &&
                        prodTrait.updated_at === val.updated_at &&
                        process.env.REACT_APP_FEATURE_FLAG !== "development" ? (
                          <div className="button button-outline-success button-sm disabled">
                            Current
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="button button-sm button-primary"
                            onClick={() =>
                              index !== publishing.index &&
                              publishing.active !== true
                                ? publish(val, index)
                                : null
                            }
                          >
                            {index !== publishing.index ? (
                              "Publish"
                            ) : (
                              <LoadingDots size="small" color="white" />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })
            : null}
        </div>
      )}
    </div>
  );
};

export default PublishMasterTraits;
